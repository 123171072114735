<template>
    <!-- <button v-bind="$attrs" class="px-6 py-4 bg-sky-500 hover:bg-sky-400 rounded-full text-white font-semibold mt-10 block"><i class="mr-2" :class="icon" ></i>{{ buttonText }}</button> -->
    <button v-bind="$attrs" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"><i v-if="icon != ''" class="mr-2" :class="icon" ></i>{{ buttonText }}</button>
</template>

<script>
    export default {
        props: {
            buttonText: {
                type: String,
                default: () => "Submit",
            },
            icon: {
                type: String,
                default: () => ''
            },
            // px: {
            //     type: String,
            //     default: () => 'px-6'
            // }
        }
    }
</script>

<style scoped>

</style>

