<template>
    <div class="block flex flex-col md:flex-row justify-between w-full border-b border-gray-300 dark:border-slate-700 pb-8">
        <div>
            <h4 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{ Name }}</h4>
            <ul class="text-base text-gray-700 dark:text-gray-300 font-semibold">
                <li v-if="typeof AgentRef === 'string' ">{{ $t('yourreference') }}: {{ AgentRef }}</li>
                <li>{{ $t('reference') }}: {{ Ref }}</li>
                <li>{{ $t('traveldate') }}: {{ $readableDateAndYear(TravelDate) }}</li>
                
            </ul>
            <div v-if="hasInvoices > '0' || itinPublished == '1' || pdfUrl" class="flex gap-6 mt-4 border border-slate-500 py-2 px-3 rounded-lg">
                <router-link v-if="hasInvoices > '0'" :to="{name: 'my-invoices', params: { ref: Ref }}" class="text-blue-600 hover:text-sky-400 dark:text-white dark:hover:text-gray-200 ">{{ $t('invoices') }}</router-link>
                <router-link v-if="itinPublished == '1'" :to="{name: 'travel-app', params: { id: Ref }}" target="_blank" class="text-blue-600 hover:text-blue-400 dark:text-blue-300 dark:hover:text-blue-400">Mobile Itinerary<i class="dark:text-blue-300 dark:hover:text-blue-400 fa-solid fa-arrow-up-right-from-square ml-2 text-xs align-text-top"></i></router-link>
                <a v-if="pdfUrl" :href="pdfUrl" target="blank" class="text-blue-600 hover:text-blue-400 dark:text-blue-300 dark:hover:text-blue-400">PDF Itinerary<i class="dark:text-blue-300 dark:hover:text-blue-400 fa-solid fa-arrow-up-right-from-square ml-2 text-xs align-text-top"></i></a>
            </div>        
        </div>
        
        <div class="flex flex-col justify-end"> 
            <ul class="text-base text-gray-700 dark:text-gray-300 font-semibold">
                <li class="md:text-right" v-if="IsInternetBooking == 'Y'">Online Booking</li>
                <li v-if="BookingStatus != ''" class="" :class="
                    {   'text-red-500': BookingStatus == 'Cancelled', 
                        'text-amber-500': BookingStatus == 'Processing', 
                        'text-amber-500': BookingStatus == 'Quote', 
                        'text-amber-500': BookingStatus == 'On Request',
                        'text-emerald-500': BookingStatus == 'Confirmed',
                    }">
                    {{ $t('status') }}: {{ BookingStatus }}
                </li>
            </ul>
        </div>
  
    </div>
</template>

<script>
import { stringify } from 'postcss'

    export default {
        props: {
            Name: {
                type: String,
                default: () => "Booking Name Missing",
            },
            Ref: {
                type: String,
                default: () => 'Ref Missing'
            },
            AgentRef: {
                // type: String,
                default: () => ''
            }
            ,
            TravelDate: {
                type: String,
                default: () => ''
            },
            BookingStatus: {
                type: String,
                default: () => ''
            },
            TotalPrice: {
                type: String,
                default: () => ''
            },
            BookingStatus: {
                type: String,
                default: () => ''
            },
            IsInternetBooking: {
                type: String,
            },
            hasInvoices: {
                type: String,
                default: () => '0'
            },
            itinPublished: {
                type: String,
                default: () => '0'
            },
            pdfUrl: {
                type: String,
                default: () => ''
            }
        },

        methods: {
            priceFormat(price) {  
                let newPrice = Number(price/100).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'NZD'
                })

                return newPrice
            }
            
        },

        }
    
</script>

<style scoped>

</style>